<template>
  <div
    :id="id"
    class="vfp"
  >
    <div
      class="vfp-bgArea"
      :class="{ 'vfp-active': isActive }"
      @dragover="setActive"
      @dragleave="cancelActive"
      @drop="fileAdded"
    >
      <div
        v-if="1 === 3"
        class="vfp-iconHolder vfp-gridItem"
      >
        <slot name="icon">
          <svg
            slot="icon"
            height="40"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 32h12v-12h8l-14-14-14 14h8zm-8 4h28v4h-28z"
              fill="#CACFD2"
            />
          </svg>
        </slot>
      </div>
      <input
        id="vfp-filePicker"
        class="vfp-inputfile vfp-gridItem"
        type="file"
        name="vfp-filePicker"
        :accept="accept"
        :multiple="allowMultiple"
        @change="fileAdded"
      >
      <label
        class="vfp-label vfp-gridItem"
        for="vfp-filePicker"
      >
        <slot name="label">
          <strong>Choose a file</strong> or drop it here
        </slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilePicker',
  props: {
    id: {
      type: String,
      required: true,
      default: 'filePicker',
    },
    accept: {
      type: String,
      default: '*/*',
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    requiresTypeCheck() {
      return this.accept !== '*/*'
    },
    acceptedTypes() {
      return this.accept.split(',')
    },
  },
  methods: {
    cancelHandlers(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    setActive(e) {
      this.isActive = true
      this.cancelHandlers(e)
    },
    cancelActive(e) {
      this.isActive = false
      this.cancelHandlers(e)
    },
    fileAdded(e) {
      this.isActive = false
      this.cancelHandlers(e)
      const wasDropped = e.dataTransfer
      const files = wasDropped ? e.dataTransfer.files : e.target.files

      if (wasDropped && !this.allowMultiple && files.length > 1) throw new Error('vue-file-picker: Multiple Files are not allowed')

      if (wasDropped && this.requiresTypeCheck) {
        for (let i = 0; i < files.length; i += 1) {
          if (this.acceptedTypes.indexOf(files[i].type) === -1) throw new Error('vue-file-picker: File type not allowed')
        }
      }
      this.$emit('vfp-file-added', files)
    },
  },
}
</script>

<style lang="scss">

.vfp {
    display: flex;
    height: 150px;

    .vfp-bgArea {
        transition: 0.3s;
        background: rgba(242, 243, 244, 0.09);
        display: grid;
        grid-template-rows: 60% 40%;
        padding: 25px 10px;
        width: 100%;
        outline: 2px dashed #7756e9;
        outline-offset: -10px;
        color: #3b3e40;
        text-align: center;
    }

    .vfp-inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
    }

    .vfp-gridItem {
        align-self: center;
        justify-self: center;
    }

    .vfp-label {
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        margin-bottom: -2.7rem;
    }

    .vfp-active {
        background-color: #D7DBDD;
        outline-color: #F2F3F4;
    }

    @media only screen and (max-width: 440px) {
        .vfp-bgArea {
            padding: 18px 10px;
            grid-template-rows: 50% 50%;
            grid-row-gap: 5px;
        }
    }
}

</style>
