<template>
  <b-overlay
    :show="loading"
    variant="transparent"
    :opacity="0.8"
    blur="2px"
    spinner-variant="primary"
  >
    <div
      v-if="portfolioId"
      class="d-flex flex-column align-items-center"
    >
      <b-avatar
        variant="light-success"
        size="lg"
      >
        <feather-icon icon="CheckIcon" />
      </b-avatar>
      <h4 class="d-block mt-1">
        Upload Successful
      </h4>
      <div class="mt-2">
        <router-link
          :to="{ name: 'portfolio-report', params: { portfolioId: portfolioId } }"
          class="btn btn-primary mr-1"
        >
          Go to Portfolio Report
        </router-link>
        <b-button
          variant="primary"
          @click="$emit('upload-new-file')"
        >
          Upload New File
        </b-button>
      </div>
    </div>
    <div
      v-else-if="error"
      class="d-flex flex-column align-items-center pb-3"
    >
      <b-avatar
        variant="light-danger"
        size="lg"
      >
        <feather-icon icon="AlertTriangleIcon" />
      </b-avatar>
      <h4 class="d-block mt-1">
        An error occured while processing the upload
      </h4>
      <span>{{ error === true ? 'Please try again' : error }}</span>
    </div>
    <div
      v-else-if="uploadResponse"
      class="p-1 py-2"
    >
      <div>
        <h3 class="mb-1">
          Messages
        </h3>
        <span class="d-block mb-2">
          <span v-if="errorResponse">The upload can not be completed:</span>
          <span v-else>The following actions will be performed as a result of this upload:</span>
        </span>
        <b-list-group flush>
          <b-list-group-item
            v-for="(message, index) in uploadResponse.messages"
            :key="index"
            class="d-flex"
          >
            <span class="mr-1">
              <feather-icon
                icon="InfoIcon"
                size="16"
              />
            </span>
            <span>{{ message }}</span>
          </b-list-group-item>
        </b-list-group>
        <span class="d-block mt-2">
          <span v-if="errorResponse">Please correct any errors and reattempt the upload.</span>
          <span v-else>Please review the feedback and confirm the upload.</span>
        </span>
      </div>

      <hr class="my-2">

      <div v-if="errorResponse">
        <h3 class="mb-1">
          Errors
        </h3>
        <b-table
          class="custom-scrollbar feedback-table"
          :fields="errorTableFields"
          :items="uploadResponse.errors"
          hover
          responsive
        />
      </div>
      <div v-else>
        <h3>Feedback</h3>
        <app-collapse accordion>
          <app-collapse-item
            v-for="(feedback, index) in uploadResponse.feedback"
            :key="index"
            :title="feedback.title"
            :is-visible="feedback.isShown"
          >
            <div>
              <b-table
                class="custom-scrollbar feedback-table"
                :fields="feedback.format === 'product' ? productTableFields : identifierTableFields"
                :items="feedback.items"
                :sticky-header="true"
                :hover="false"
                responsive
              />
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import {
  BTable, BOverlay, BAvatar, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BOverlay,
    BTable,
    BListGroup,
    BListGroupItem,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    uploadResponse: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      portfolioId: null, // newly created or updated portfolio id
      errorTableFields: [
        { key: 'row', label: 'Row', thStyle: { width: '15%' } },
        { key: 'error', label: 'Error', thStyle: { width: '75%' } },
        { key: 'content', label: 'Content', thStyle: { width: '15%' } },
      ],
    }
  },
  computed: {
    errorResponse() {
      return this.uploadResponse && this.uploadResponse.outcome === 'error'
    },
    identifierTableFields() {
      const fields = [
        { key: 'identifier', label: 'Identifier' },
      ]

      if (this.uploadResponse.isNotional) {
        fields.push({ key: 'notional', label: 'Notional' })
      }

      return fields
    },
    productTableFields() {
      const fields = [
        { key: 'identifier', label: 'Identifier' },
        { key: 'name', label: 'Name' },
      ]

      if (this.uploadResponse.isNotional) {
        fields.push({ key: 'notional', label: 'Notional' })
      }

      return fields
    },
  },
  watch: {
    uploadResponse(newValue) {
      if (newValue === null) {
        // Reset state
        this.loading = false
        this.error = null
        this.portfolioId = null
      } else if (newValue.outcome === 'error') {
        this.$emit('upload-error', this.error)
      }
    },
  },
  mounted() {
    if (this.uploadResponse && this.uploadResponse.outcome === 'error') {
      this.$emit('upload-error', this.error)
    }
  },
  methods: {
    confirmUpload() {
      this.error = null
      this.loading = true
      this.portfolioId = null

      const formData = new FormData()
      formData.append('uploadId', this.uploadResponse.uploadId)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.$http.post('portfolio.cfc?method=performUpload', formData, config)
        .then(response => {
          if (response.data.outcome === 'success') {
            this.portfolioId = response.data.portfolioId
            this.$emit('upload-confirmed', this.portfolioId)
          } else {
            this.error = response.data.message
            this.$emit('upload-error', this.error)
          }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.feedback-table {
  max-height: 250px;
}
</style>
