<template>
  <div>
    <div
      v-if="action === 'create'"
      class="content"
    >
      <!-- Form form inputting portfolio name -->
      <b-form-group>
        <label for="portfolioName">Portfolio Name</label>
        <b-form-input
          id="portfolioName"
          v-model="portfolioName"
          type="text"
          :state="validationError === null ? null : false"
          placeholder="My Portfolio"
          @focus="portfolioName = portfolioName === null ? '' : portfolioName"
        />
        <b-form-invalid-feedback>
          {{ validationError }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div
      v-else
      class="content"
    >
      <h4>Select Portfolio</h4>
      <b-input-group class="py-1 input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="filter"
          placeholder="Search (ID, name or date)"
          :disabled="portfolios.length < 1"
        />
      </b-input-group>
      <b-table
        ref="portfoliosTable"
        class="mb-0 custom-scrollbar portfolios-table"
        :fields="portfoliosTableFields"
        :items="filterResult"
        :sticky-header="true"
        selectable
        select-mode="single"
        responsive
        hover
        @row-selected="portfolioSelected"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormInvalidFeedback, BInputGroup, BInputGroupPrepend, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
  },
  refs: {
    portfoliosTable: {
      type: BTable,
      required: true,
    },
  },
  props: {
    action: {
      type: String,
      default: null,
    },
    selectedPortfolio: { // Needed to track changes and reset table selection when previous tab (action) is changed
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      portfolioName: null,
      validationError: null,
      filter: '',
      portfoliosTableFields: [
        {
          key: 'portfolioId', label: 'ID', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'portfolioName', label: 'Portfolio', thStyle: { width: '32%' }, sortable: true,
        },
      ],
    }
  },
  computed: {
    portfolios() {
      return this.$store.state.app.portfolios
    },
    filterResult() {
      if (this.filter && this.filter.length > 0) {
        return this.portfolios.filter(item => (item.portfolioName.toLowerCase().search(this.filter.toLowerCase()) !== -1))
      }
      return this.portfolios
    },
    validated() {
      if (this.action === 'create') {
        return this.validationError || !this.portfolioName
      }
      return !this.selectedPortfolio
    },
  },
  watch: {
    portfolioName() {
      // Clear validation error
      this.validationError = null

      // Set the name
      this.$emit('name-set', this.portfolioName)

      // Check if portfolio name is not empty
      if (this.portfolioName.length < 1) {
        this.validationError = 'Portfolio Name Cannot be Empty'
        this.$emit('name-set', null)
      }

      // Check if the portfolio name is already used
      const nameExists = this.portfolios.find(
        portfolio => portfolio.portfolioName.toLowerCase() === this.portfolioName.toLowerCase(),
      )

      if (nameExists) {
        // Set input error message
        this.validationError = 'Portfolio Name Already Exists. Try Another One.'
        this.$emit('name-set', null)
      }
    },
    selectedPortfolio(newValue) {
      // Clear the selected item from the table when the selected portfolio is changed
      if (newValue === null) {
        this.$refs.portfoliosTable.clearSelected()
      }
    },
    action() {
      this.portfolioName = null
      if (this.$refs.portfoliosTable !== undefined) {
        this.$refs.portfoliosTable.clearSelected()
      }
    },
  },
  methods: {
    portfolioSelected(item) {
      this.$emit('portfolio-selected', item[0])
    },
  },
}
</script>

<style lang="scss" scoped>
.portfolios-table {
  max-height: 300px;
}
</style>
