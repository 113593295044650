<template>
  <div>
    <form-wizard
      v-show="!newPortfolioId"
      ref="uploadStepper"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :hide-buttons="true"
      finish-button-text="Confirm Upload"
      back-button-text="Previous"
      class="mb-3"
      @on-change="onStepperChange"
      @on-complete="$refs.confirm.confirmUpload()"
    >
      <!-- Choose Action Tab -->
      <tab-content
        title="Choose Action"
        :before-change="validateChooseAction"
      >
        <div class="stepper-tab-content">
          <choose-action
            ref="chooseAction"
            @action-selected="actionSelected"
          />
        </div>
      </tab-content>

      <!-- Select Portfolio -->
      <tab-content
        :title="selectedAction === null ? 'Portfolio' : (selectedAction === 'create' ? 'Portfolio Name' : 'Select Portfolio')"
        :before-change="validateSelectPortfolio"
      >
        <div class="stepper-tab-content">
          <select-portfolio
            ref="selectPortfolio"
            :action="selectedAction"
            :selected-portfolio="selectedPortfolio"
            @portfolio-selected="portfolioSelected"
            @name-set="portfolioNameSet"
          />
        </div>
      </tab-content>

      <!-- Upload Spreadsheet -->
      <tab-content
        title="Upload"
        :before-change="validateUpload"
      >
        <div class="stepper-tab-content">
          <upload
            :action="selectedAction"
            :portfolio="selectedPortfolio"
            :name="newPortfolioName"
            @file-uploaded="fileUploaded"
          />
        </div>
      </tab-content>

      <!-- Confirm -->
      <tab-content
        title="Confirm"
      >
        <div class="stepper-tab-content">
          <confirm
            ref="confirm"
            :upload-response="fileUploadResponse"
            @upload-new-file="reset"
            @upload-confirmed="uploadConfirmed"
            @upload-error="uploadError = true"
          />
        </div>
      </tab-content>

      <!-- Navigation Buttons -->
      <div
        v-if="!newPortfolioId"
        class="d-flex justify-content-between mb-2 mt-1"
      >
        <div>
          <b-button
            v-show="$refs.uploadStepper !== undefined && $refs.uploadStepper.activeTabIndex !== 0"
            variant="outline-secondary"
            class="py-1"
            @click.prevent="previousTab"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50"
            />
            Previous
          </b-button>
        </div>
        <b-button
          v-if="uploadError"
          variant="primary"
          class="py-1"
          :disabled="nextDisabled"
          @click.prevent="reset"
        >
          Reset
          <feather-icon
            icon="RotateCcwIcon"
            class="ml-50"
          />
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="py-1"
          :disabled="nextDisabled"
          @click.prevent="stepperNext"
        >
          {{ activeTabIndex === 3 ? 'Confirm Upload' : 'Next' }}
          <feather-icon
            icon="ChevronRightIcon"
            class="ml-50"
          />
        </b-button>
      </div>
    </form-wizard>
    <b-card v-if="newPortfolioId">
      <b-card-body>
        <div
          class="d-flex flex-column align-items-center py-3"
        >
          <b-avatar
            variant="light-success"
            size="lg"
          >
            <feather-icon icon="CheckIcon" />
          </b-avatar>
          <h4 class="d-block mt-1">
            Upload Successful
          </h4>
          <div class="mt-2">
            <router-link
              :to="{ name: 'portfolio-report', params: { portfolioId: newPortfolioId } }"
              class="btn btn-primary mr-1"
            >
              Go to Portfolio Report
            </router-link>
            <b-button
              variant="primary"
              @click.prevent="reset"
            >
              Upload New File
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ChooseAction from '@/views/upload-spreadsheet/ChooseAction.vue'
import SelectPortfolio from '@/views/upload-spreadsheet/SelectPortfolio.vue'
import Upload from '@/views/upload-spreadsheet/Upload.vue'
import Confirm from '@/views/upload-spreadsheet/Confirm.vue'
import strings from '@/strings'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCardBody,
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ChooseAction,
    SelectPortfolio,
    Upload,
    Confirm,
  },
  data() {
    return {
      selectedAction: null,
      selectedPortfolio: null,
      newPortfolioName: null,
      fileUploadResponse: null,
      newPortfolioId: null,
      uploadError: null,
      activeTabIndex: 0,
    }
  },
  computed: {
    nextDisabled() {
      if (this.activeTabIndex === 0) {
        return this.selectedAction === null
      }
      if (this.activeTabIndex === 1) {
        return this.$refs.selectPortfolio.validated
      }
      if (this.activeTabIndex === 2) {
        return this.fileUploadResponse === null
      }
      return false
    },
  },
  watch: {
    selectedAction() {
      // Rest next step/tab values
      this.selectedPortfolio = null
      this.newPortfolioName = null
      this.fileUploadResponse = null
      this.uploadError = null
    },
    selectedPortfolio() {
      // Rest next step/tab values
      this.newPortfolioName = null
      this.fileUploadResponse = null
      this.uploadError = null
    },
    newPortfolioName() {
      // Rest next step/tab values
      this.selectedPortfolio = null
      this.fileUploadResponse = null
      this.uploadError = null
    },
    fileUploadResponse(value) {
      if (value === null) {
        this.uploadError = null
      }
    },
  },
  methods: {
    actionSelected(action) {
      this.selectedAction = action
    },
    validateChooseAction() {
      return new Promise((resolve, reject) => {
        if (this.selectedAction) {
          resolve(true)
        } else {
          this.$bvToast.toast('Select an action to move to the next step.', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
          reject()
        }
      })
    },
    validateSelectPortfolio() {
      return new Promise((resolve, reject) => {
        if (this.selectedAction === 'create') {
          if (this.newPortfolioName) {
            resolve(true)
          } else {
            this.$bvToast.toast('Set a portfolio name to move to the next step.', {
              title: strings.defaultErrorTitle,
              variant: 'danger',
              solid: true,
            })
            reject()
          }
        } else if (this.selectedPortfolio) {
          resolve(true)
        } else {
          this.$bvToast.toast('Select a portfolio.', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
          reject()
        }
      })
    },
    validateUpload() {
      return new Promise((resolve, reject) => {
        if (this.fileUploadResponse) {
          resolve(true)
        } else {
          this.$bvToast.toast('Upload a file to move to the next step.', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
          reject()
        }
      })
    },
    portfolioSelected(item) {
      this.selectedPortfolio = item
    },
    portfolioNameSet(name) {
      this.newPortfolioName = name
    },
    fileUploaded(response) {
      this.fileUploadResponse = response
      if (response) {
        this.nextTab()
      }
    },
    nextTab() {
      this.$refs.uploadStepper.nextTab()
    },
    previousTab() {
      this.$refs.uploadStepper.prevTab()
    },
    reset() {
      this.uploadError = null

      // Reset the selected action and the rest of tabs will automatically reset
      this.$refs.chooseAction.selectedAction = null

      // Reset new portfolio ID to show next and previous buttons
      this.newPortfolioId = null

      // Reset stepper
      this.$refs.uploadStepper.reset()
    },
    uploadConfirmed(newPortfolioId) {
      this.newPortfolioId = newPortfolioId

      // Update store value
      this.$store.dispatch('app/updatePortfolios')
      this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
      this.$store.commit('app/ADD_UPDATED_PORTFOLIO', this.newPortfolioId)
    },
    stepperNext() {
      if (this.activeTabIndex === 3) {
        this.$refs.confirm.confirmUpload()
      } else {
        this.nextTab()
      }
    },
    onStepperChange(prev, next) {
      this.activeTabIndex = next
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>
<style lang="scss" scoped>
.stepper-tab-content {
  max-width: 700px;
  min-height: 250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
