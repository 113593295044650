<template>
  <b-overlay
    :show="loading"
    variant="transparent"
    :opacity="0.8"
    blur="2px"
    spinner-variant="primary"
  >
    <div>
      <h5>Select File to Upload</h5>

      <div class="my-1">
        <p>Upload a spreadsheet containing rows of product identifiers and optionally notionals.</p>
        <b-collapse v-model="showDescription">
          <p>An Excel spreadsheet (XLSX/XLSM/XLS format) or CSV file can be uploaded.
            Each row or line must contain one product. The first row or line must be a header.</p>
          <p>The first column labelled Identifier must have either product IDs or CUSIPs.
            The two types of identifiers can be mixed freely.</p>
          <p>Example data with one column only, containing a mix of IDs and CUSIPs:</p>
          <pre class="mx-3 px-2">Identifier
12345678
12345AB89
87654321
98765CD12</pre>
          <p>A second, optional column labelled Notional may be supplied, which must contain the product notionals.
            In case of a portfolio update, this may be zero to remove the product from the portfolio.</p>
          <p>Example data with two columns:</p>
          <pre class="mx-3 px-2">Identifier,Notional
12345678,12000000
12345AB89,300000
87654321,750000
98765CD12,123456.78</pre>
          <p>The header labels and data values must not be quoted. No blank rows or lines are allowed.</p>
        </b-collapse>
        <span
          class="d-block text-primary cursor-pointer"
          @click="showDescription = !showDescription"
        >
          {{ showDescription ? 'Less' : 'More' }} Details
        </span>
      </div>

      <div>
        <b-card-text
          v-if="file && !error"
          class="my-1"
        >
          Selected file: <strong>{{ file ? file.name : '' }}</strong>
          <b-button
            class="ml-1"
            variant="flat-primary"
            @click.prevent="clearFile"
          >Clear</b-button>
        </b-card-text>
      </div>

      <error-display
        v-if="error && !loading"
        message="Upload Failed. Check Your Connection and Try Again."
        @action-clicked="uploadFile"
      />
      <div v-else-if="file === null || loading">
        <file-picker
          id="file-selector"
          @vfp-file-added="fileAdded"
        >
          <template #label>
            <div class="my-1">
              <span>Drag an Excel spreadsheet (XLSX/XLSM/XLS) or CSV file here</span>

              <div class="pt-3">
                <span class="btn btn-primary">Choose File</span>
              </div>
            </div>
          </template>
        </file-picker>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import {
  BCardText, BOverlay, BButton, BCollapse,
} from 'bootstrap-vue'
import FilePicker from '@/views/components/FilePicker.vue'
import ErrorDisplay from '@/views/components/ErrorDisplay.vue'

export default {
  components: {
    BCardText,
    BOverlay,
    BButton,
    BCollapse,
    FilePicker,
    ErrorDisplay,
  },
  props: {
    action: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    portfolio: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      loading: false,
      error: null,
      messages: null,
      showDescription: false,
    }
  },
  watch: {
    file() {
      if (this.file !== null) {
        this.uploadFile()
      }
    },
    action() {
      this.file = null
      this.error = null
      this.messages = null
    },
    name() {
      this.file = null
      this.error = null
      this.messages = null
    },
    portfolio() {
      this.file = null
      this.error = null
      this.messages = null
    },
  },
  methods: {
    uploadFile() {
      this.loading = true
      this.error = null

      // Upload file
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('action', this.action)

      if (this.action === 'create') {
        formData.append('name', this.name)
      } else {
        formData.append('portfolioId', this.portfolio.portfolioId)
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.$http.post('portfolio.cfc?method=prepareUpload', formData, config)
        .then(response => {
          this.$emit('file-uploaded', response.data)
        })
        .catch(() => {
          this.error = true
        })
        .then(() => {
          this.loading = false
        })
    },
    fileAdded(files) {
      // eslint-disable-next-line prefer-destructuring
      this.file = files[0]
    },
    clearFile() {
      this.file = null
      this.$emit('file-uploaded', null)
    },
  },
}
</script>
