<template>
  <div>
    <div
      class="mt-2 action-card"
      @click="selectedAction = 'create'"
    >
      <b-row>
        <b-col
          cols="2"
          lg="1"
        >
          <div class="h-100 d-flex justify-content-end pt-0 pr-1">
            <b-form-radio
              v-model="selectedAction"
              name="action-radios"
              value="create"
            />
          </div>
        </b-col>
        <b-col
          cols="10"
          lg="11"
        >
          <h4>Create New Portfolio</h4>
          <p>Create a new portfolio and add products with or without specifying notionals</p>
        </b-col>
      </b-row>
    </div>

    <div
      class="mt-2 action-card"
      @click="selectedAction = 'update'"
    >
      <b-row>
        <b-col
          cols="2"
          lg="1"
        >
          <div class="h-100 d-flex justify-content-end pt-0 pr-1">
            <b-form-radio
              v-model="selectedAction"
              name="action-radios"
              value="update"
            />
          </div>
        </b-col>
        <b-col
          cols="10"
          lg="11"
        >
          <h4>Update Portfolio</h4>
          <p>Add products to a portfolio</p>
          <p>Update notionals of some or all products</p>
          <p>Remove products by setting their notionals to zero</p>
        </b-col>
      </b-row>
    </div>

    <div
      class="mt-2 action-card"
      @click="selectedAction = 'replace'"
    >
      <b-row>
        <b-col
          cols="2"
          lg="1"
        >
          <div class="h-100 d-flex justify-content-end pt-0 pr-1">
            <b-form-radio
              v-model="selectedAction"
              name="action-radios"
              value="replace"
            />
          </div>
        </b-col>
        <b-col
          cols="10"
          lg="11"
        >
          <h4>Replace Portfolio</h4>
          <p>Replace all products in a portfolio with or without specifying notionals</p>
        </b-col>
      </b-row>
    </div>

    <div
      class="mt-2 action-card"
      @click="selectedAction = 'remove'"
    >
      <b-row>
        <b-col
          cols="2"
          lg="1"
        >
          <div class="h-100 d-flex justify-content-end pt-0 pr-1">
            <b-form-radio
              v-model="selectedAction"
              name="action-radios"
              value="remove"
            />
          </div>
        </b-col>
        <b-col
          cols="10"
          lg="11"
        >
          <h4>Remove Products</h4>
          <p>Remove products from a portfolio using only their identifiers</p>
        </b-col>
      </b-row>
    </div>

  </div>
</template>
<script>
import { BRow, BCol, BFormRadio } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
  },
  data() {
    return {
      selectedAction: null,
    }
  },
  watch: {
    selectedAction(newValue) {
      this.$emit('action-selected', newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.action-card {
  cursor: pointer;
}
</style>
